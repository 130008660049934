<template>
  <v-container fluid>
    <v-dialog
      v-model="carga"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-h4 font-weight-light white--text">
          SUBIENDO DATA...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row
      v-if="!carga"
      justify="center"
    >
      <div
        class="d-flex flex-column justify-space-between align-center"
      >
        <v-img
          :src="urlLogo"
          max-width="380"
        />
      </div>
      <v-col
        cols="12"
        md="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Menú Principal
            </div>
            <div class="text-subtitle-1 font-weight-light">
              {{ nombreUsuario }}, actualice la información del menú desplegable del inicio
            </div>
          </template>
          <v-row justify="center">
            <v-col
              cols="12"
              md="9"
            >
              <v-card-title>
                <v-btn
                  color="red"
                  fab
                  x-small
                  dark
                  @click="dialogJuzgado = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn> <span class="text-subtitle-1 font-weight-light">Nuevo submenú</span>
                <v-spacer />
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  class="ml-auto"
                  label="Buscar..."
                  hide-details
                  single-line
                  style="max-width: 250px;"
                />
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                dense
                class="elevation-1"
                fixed-header
                :loading="carga"
                loading-text="Cargando, por favor espere"
                :footer-props="{
                  'items-per-page-options': [10, 25, 50, -1],
                  'items-per-page-text': 'Mostrando:',
                  'items-per-page-all-text': 'Todos'
                }"
                no-data-text="No hay datos registrados"
                no-results-text="No se encontraron resultados"
              >
                <template v-slot:[`item.editar`]="{ item }">
                  <v-btn
                    color="green"
                    fab
                    x-small
                    dark
                    @click="cargarData(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.tipoLista`]="{ item }">
                  <span><v-chip
                    class="ma-2"
                    color="green"
                    label
                    text-color="white"
                  >
                    <v-icon>
                      mdi-scale-balance
                    </v-icon> {{ item.tipoLista === '0' ? 'Superior' : item.tipoLista === '1' ? 'Especializados' : item.tipoLista === '2' ? 'Mixtos' : item.tipoLista === '3' ? 'Paz Letrado' : 'ERROR' }}
                  </v-chip></span>
                </template>
                <template v-slot:[`item.busca`]="{ item }">
                  <span> {{ item.busca.toUpperCase() }} </span>
                </template>
              </v-data-table>
            </v-col>
            <div
              class="text-center"
            >
              <v-form
                ref="form"
                lazy-validation
              >
                <v-dialog
                  v-model="dialogJuzgado"
                  width="800"
                  persistent
                >
                  <v-card>
                    <v-card-title class="text-h3 grey lighten-2">
                      Datos del juzgado
                    </v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-text-field
                            v-model="nombreMenu"
                            label="Nombre del submenu"
                            prepend-icon="mdi-account-tie"
                            :rules="[(v) => !!v || 'Campo requerido']"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="8"
                        >
                          <v-text-field
                            v-model="buscaMenu"
                            label="Filtrar por"
                            prepend-icon="mdi-database-search"
                            :rules="[(v) => !!v || 'Campo requerido']"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-combobox
                            v-model="listaSeleccionada"
                            :items="tipoListas"
                            item-text="text"
                            item-value="value"
                            label="Menú al que pertenece"
                            prepend-icon="mdi-format-list-bulleted-square"
                            :rules="[(v) => !!v || 'Campo requerido']"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-divider />

                    <v-card-actions>
                      <v-btn
                        color="primary"
                        text
                        class="mr-0"
                        @click="addMenu()"
                      >
                        {{ textoBoton }}
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        text
                        @click="cerrarDialog()"
                      >
                        Cerrar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-form>
            </div>
          </v-row>
          <v-divider />
          <v-spacer />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'Upload',
    data: () => ({
      usuario: '',
      nombreUsuario: '',
      mesUpdate: '',
      sedeCorteData: '',
      carga: false,
      headers: [
        {
          text: 'Editar',
          value: 'editar',
        },
        {
          text: 'Nombre',
          value: 'nombre',
        },
        {
          text: 'Filtrar por',
          value: 'busca',
        },
        {
          text: 'Menú',
          value: 'tipoLista',
        },
      ],
      archivoCsv2: [],
      dataUpdate: [],
      urlDataSede: '',
      sumaI: 0,
      incorrectos: 0,
      urlLogo: '',
      urlMenu: '',
      items: [],
      search: '',
      dialogJuzgado: false,
      nombreMenu: '',
      buscaMenu: '',
      listaSeleccionada: '',
      tipoListas: [{ text: 'SALAS SUPERIORES', value: 0 }, { text: 'JUZGADOS ESPECIALIZADOS', value: 1 }, { text: 'JUZGADOS MIXTOS', value: 2 }, { text: 'JUZGADOS DE PAZ LETRADO', value: 3 }],
      dataJuzgado: {},
      textoBoton: 'AÑADIR',
      updateJuzgado: false,
      nuevoJuzgado: false,
    }),
    created () {
      if (!this.dataUsuario || parseInt(this.dataUsuario.es_admin) !== 1) {
        localStorage.removeItem('usuarioDataCSJLA')
        this.$router.push('/')
      }
      this.usuario = localStorage.getItem('usuarioDataCSJLA')
      this.sedeCorteData = localStorage.getItem('sedeCorteData')
      this.nombreUsuario = localStorage.getItem('nombreUsuarioData')
      this.urlMenu = `${window.__env.dataCfg.urlApiVisitas}api/menu/`
      this.urlDataSede = `${window.__env.dataCfg.urlApiVisitas}api/sedes/`
      this.leerDataSede()
      this.leerMenu()
    },
    mounted () {
      window.scrollTo(0, 0)
      this.$store.commit('SET_VALOR', false)
    },
    methods: {
      cargarData (item) {
        this.dialogJuzgado = true
        this.updateJuzgado = true
        this.nuevoJuzgado = false
        this.textoBoton = 'ACTUALIZAR'
        this.nombreMenu = item.nombre
        this.buscaMenu = item.busca.toUpperCase()
        this.idEditar = item.idMenu
        // this.listaSeleccionada = parseInt(item.idMenu)
        var x = parseInt(item.tipoLista)
        this.listaSeleccionada = this.tipoListas.find(item => item.value === x)
        /* console.log(listaTodo.find(item => item.value === x).text)
        console.log(listaTodo.find(item => item.value === x).value) */
        /* this.listaSeleccionada.text = listaTodo.find(item => item.value === x)[0].text
        this.listaSeleccionada.value = x */
      },
      addMenu () {
        if (this.$refs.form.validate()) {
          const data = {
            nombre: this.nombreMenu,
            busca: this.buscaMenu,
            tipoLista: this.listaSeleccionada.value,
            idMenu: this.idEditar,
          }
          if (this.updateJuzgado) {
            axios
              .put(this.urlMenu, data)
              .then((r) => {
                r.data.resp === true ? alert('Se editó el submenú') : alert('No se pudo editar el submenú')
                this.leerMenu()
                this.cerrarDialog()
              })
              .catch((err) => {
                alert('Servicio no disponible' + err)
              })
          } else {
            axios
              .post(this.urlMenu, data)
              .then((r) => {
                r.data.resp === true ? alert('Se registró el submenú') : alert('No se registró el submenú')
                this.leerMenu()
                this.cerrarDialog()
              })
              .catch((err) => {
                alert('Servicio no disponible' + err)
              })
          }
        }
      },
      leerDataSede () {
        axios
          .get(
            this.urlDataSede,
          )
          .then((response) => {
            this.dataSede = response.data.array.filter(item => item.id === this.sedeCorteData)
            this.urlLogo = 'assets/' + this.dataSede[0].logo
            if (this.urlLogo === 'assets/') { this.urlLogo = 'assets/pj-logo.svg' }
            // this.dataSede = Object.values(response.data).filter(item => item.id === this.sedeCorteData)
            // console.log(Object.values(response.data))
          })
          .catch((e) => {
          console.log(e); // eslint-disable-line
          })
      },
      leerMenu () {
        axios
          .get(
            this.urlMenu,
          )
          .then((response) => {
            this.items = response.data.array
          })
          .catch((e) => {
          console.log(e); // eslint-disable-line
          })
      },
      cerrarDialog () {
        this.dialogJuzgado = false
        this.updateJuzgado = false
        this.nuevoJuzgado = true
        this.$refs.form.reset()
        this.textoBoton = 'AÑADIR'
      },
    },
  }
</script>
